<!-- 销售站点主管账号 -->
<template lang="">
<div style="height:100%;padding:0 20px 10px;box-sizing:border-box">
    <div class="selectionBar">
        <div class="flex flex-ai-c flex-wrap">
            <label>场景：</label>
            <!-- <el-select v-model="select.is_stop" placeholder="状态" clearable style="width:140px" @change="curr=1;getList()">
                <el-option label="启用" value="1"></el-option>
                <el-option label="禁用" value="2"></el-option>
            </el-select>
            <el-input placeholder="搜索" v-model.trim="searchValue" :clearable="true" size="small" class="input-with-select" prefix-icon="el-icon-search" style="width:150px;margin-right:auto;" @input="curr=1;getList()"></el-input> -->
            <el-button type="primary" block size="small" style="margin-left:auto" @click="edit()">新建</el-button>
        </div>
    </div>
    <!-- 表格 -->
    <div class="table" style="height:calc( 100% - 175px )">
        <el-table ref="table" :data="list" highlight-current-row :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" @sort-change='getList()' height="calc( 100% - 41px )">
            <el-table-column prop="name" label="姓名" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="username" label="登录账号" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="mobile" label="电话" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="操作" align="center" >
                <template slot-scope="scope">
                    <!-- <el-button plain type="warning" size="small" @click="handleSetPwd(scope.row.id)">重置密码</el-button> -->
                    <el-button plain type="success" size="mini" icon="el-icon-edit-outline" @click="edit(scope.row)"></el-button>
                    <el-button plain type="danger" size="mini" icon="el-icon-delete" @click="handleDel(scope.row.id)"></el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="paging">
            <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
        </div>
    </div>
    <!-- 新建编辑 -->
    <el-dialog :title="dialogTitle" :modal-append-to-body="false" :close-on-click-modal="false" :visible="dialogIsShow" width="60%" @close="dialogClose">
        <el-form :model="formData" ref="form" label-position="top" size="small" :show-message="false" :status-icon="true" class="alertForm">
            <el-form-item label="姓名" prop="name" required>
                <el-input v-model.trim="formData.name" placeholder="请填写" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="登录账号" prop="username" required>
                <el-input v-model="formData.username" placeholder="请填写" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password" :required="formData.id?false:true">
                <el-input v-model="formData.password" :placeholder="formData.id?'如不修改留空即可':'请填写'" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="电话" prop="mobile" required>
                <el-input v-model.number="formData.mobile" placeholder="请填写" :clearable="true"></el-input>
            </el-form-item>
            <!-- <el-form-item label="所属站点" prop="site_id" required v-if="$root.userInfo.site_id==0">
                <el-select v-model="formData.site_id" filterable placeholder="请选择">
                    <el-option v-for="item in siteList" :key="item.id" :label="item.name" :value="item.id">
                        <span style="float: left">{{ item.name }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.tel }}</span>
                    </el-option>
                </el-select>
            </el-form-item> -->
            <!-- <el-form-item label="角色类型" prop="type" required>
                <el-select v-model="formData.type" filterable placeholder="请选择">
                    <el-option label="总站" value="1"></el-option>
                    <el-option label="销售站点" value="2"></el-option>
                    <el-option label="汽配厂" value="3"></el-option>
                </el-select>
            </el-form-item> -->
            <!-- <el-form-item label="是否管理员" prop="is_admin" required>
                <el-select v-model="formData.is_admin" filterable placeholder="请选择">
                    <el-option label="管理员" value="1"></el-option>
                    <el-option label="非管理员" value="2"></el-option>
                </el-select>
            </el-form-item> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogClose" size="small">取 消</el-button>
            <el-button type="primary" @click="handleSubmit" class="save" size="small">保 存</el-button>
        </div>
    </el-dialog>

</div>
</template>

<script>
export default {
    data: function() {
        return {
            searchValue: '', //导入input值
            select: {},
            list: [],
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数

            formData: {},
            dialogTitle: '',
            dialogIsShow: false,
            siteList:[],
        }
    },
    mounted: function() {
        this.getList();
        this.getAdmin()
        this.formData.site_id=sessionStorage.getItem('bureau_id')
    },
    methods: {
        //站点
        getAdmin: function() {
            this.http.post('/sales.site/getList',{},false).then(re => {
                this.siteList = re.data.list;
            })
        },
        getList: function() {
            this.http.post('/admin.admin/getList', {
                curr: this.curr,
                row: this.row,
                is_stop:this.select.is_stop,
                search: this.searchValue
            }).then(re => {
                this.list = re.data.list;
                this.curr = re.data.curr;
                this.pages = re.data.pages;
                this.count = re.data.count;
                this.$nextTick(() => {
                    this.$refs.table.doLayout()
                })
            })
        },
        edit: function(row = {}) {
            if (typeof row.id == 'undefined') {
                this.dialogTitle = "新建账号";
            } else {
                this.dialogTitle = "编辑账号";
                this.formData = {};
                row.password='';
                this.formData = JSON.parse(JSON.stringify(row));
                this.$set(this.formData,'type',String(this.formData.type));
                this.$set(this.formData,'is_admin',String(this.formData.is_admin));
            }
            this.dialogIsShow = true
        },
        handleDel(id){
            this.$confirm('此操作将永久删除该条信息, 是否继续', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then( () => {
               this.http.post('/admin.admin/delete',{
                   id:id
               }).then(()=>{
                    this.getList();
                    this.$message({
                        message: '成功删除',
                        type: 'success'
                    })
               })
            })
        },
        //监听表单关闭事件
        dialogClose() {
            this.dialogIsShow = false;
            this.formData = {};
            this.$refs.form.resetFields();
        },
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.formData.type=2;
                    this.formData.is_admin=2;
                    this.formData.password=this.formData.password?this.$md5(this.formData.password):'';
                    this.http.post('/admin.admin/edit', this.formData).then(() => {
                        this.dialogClose();
                        this.getList();
                        this.$message({
                            message: '操作成功',
                            type: 'success'
                        })
                    })
                }
            })
        },
        handleSetPwd(user_id){
            this.http.post('/user/resetPassword',{
                user_id
            }).then(() => {
                this.$message({
                    message: '已重置',
                    type: 'success'
                })
            })
        },
        //设置账号禁用和开启
        setSwitch (id, _switch) {
            var api = "/user/setStop";
            this.http.post(api, {
                id,
                is_stop: _switch,
            }).then(() => {
                this.getList();
                this.$message({
                    message: '设置成功',
                    type: 'success'
                });
            });
        },
        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>
